
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
import { openRoadApiUrl, version } from "/src/constants/config";
import axios from 'axios';

export default {
	data() {
		return {
            apiHeaders: {},
            authorization: {},
            currentUserDetail: {},
		}
    },

    mounted() {		
        if(this.currentUser){
            this.authorization = {Authorization: 'Bearer ' + this.currentUser.accessToken };
            this.apiHeaders = { headers: {Authorization: 'Bearer ' + this.currentUser.accessToken } };
        }      
    },
    
    methods: {
        ...mapActions(["setLang", "signOut"]),

        getCurrentUserDetail(){
            let url = openRoadApiUrl + "/api/services/" + version + "/user/me";
            axios.get(url, this.apiHeaders)
                .then(response => this.setUserDetail(response))
                .catch(error => this.onError(error));
        },

        setUserDetail(response){   
            this.currentUserDetail.id = response.data.result.id;
            this.currentUserDetail.userName = response.data.result.userName ? response.data.result.userName : "-";
            this.currentUserDetail.name = response.data.result.name ? response.data.result.name : "-";
            this.currentUserDetail.surname = response.data.result.surname ? response.data.result.surname : "-";
            this.currentUserDetail.emailAddress = response.data.result.emailAddress ? response.data.result.emailAddress : "-";
            this.currentUserDetail.nric = response.data.result.nric ? response.data.result.nric : "-";
            this.currentUserDetail.companyName = response.data.result.companyName ? response.data.result.companyName : "-";
            this.currentUserDetail.companyRegistrationNo = response.data.result.companyRegistrationNo ? response.data.result.companyRegistrationNo : "-";
            this.currentUserDetail.address = response.data.result.address ? response.data.result.address : "-";
            this.currentUserDetail.isActive = response.data.result.isActive ? response.data.result.isActive : "-";
            this.currentUserDetail.fullName = response.data.result.fullName ? response.data.result.fullName : "-";
            this.currentUserDetail.lastLoginTime = response.data.result.lastLoginTime ? response.data.result.lastLoginTime : "-";
            this.currentUserDetail.creationTime = response.data.result.creationTime ? moment(String(response.data.result.creationTime)).format('MM / DD / YYYY') : "-";    
            this.currentUserDetail.mobile = "-";
        },
    },

    computed: {
        ...mapGetters({
            currentUser: "currentUser",
          })
    }
}