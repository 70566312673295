<template>
    <div class="page-height background-detail">
         <v-container class="page-content-offset profile-position cctv-container-body cctv-background">
            <div class="row">
                <div class="col-12">
                    <span class="bill-all-invoices"><b>Install CCTV Mobile App</b></span>
                </div>
            </div>

            <div class="row pt-5">
                <div class="col-12 col-md-4 pt-0 pb-1 cctv-image-position mt-auto mb-auto">
                    <img class="cctv-image" src="../assets/img/mi_cctv.png" />
                </div>
                <div class="col-12 col-md-8 pt-0 pb-0">
                    <div class="row">
                        <div class="col-12">
                            <span class="cctv-content">In order to access to your Xiaomi CCTV, please follow the following steps:</span>
                        </div>
                        <div class="col-12">
                            <ol class="cctv-content">
                              <li class="pt-0">Download and Install Mi Home app 
                                <img class="pl-2" src="../assets/img/xiaomi.png" />
                                <br>
                                <a href="https://apps.apple.com/us/app/mi-home-xiaomi-smart-home/id957323480" target="_blank">
                                  <img class="app-store-image pr-2" src="../assets/img/App-Store.png" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.xiaomi.smarthome&hl=en&gl=US" target="_blank">
                                  <img class="app-store-image" src="../assets/img/google-play-badge.png" />
                                </a>
                              </li>
                              <li class="pt-3">Register a new user account.</li>
                              <li class="pt-3 pb-0">Please contact our Operations Manager, Ms. Charmaine, at +6016 - 316 7386 to connect your dedicated CCTV</li>
                            </ol>
                        </div>
                    </div>
                </div>                
            </div>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { openRoadApiUrl, version } from "/src/constants/config";
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import moment from 'moment';

export default {
  mixins: [currentUserMixin],
  data() {
    return {
    }
  },

  components: {
  },
  
    mounted() {		
    }, 

    methods: {
    },

    computed: {
    },    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
